<template>
    <div>
        <Navbar page="Editar gestão de incidentes" link="/incidentes" nameLink="Gestão de Incidentes" />
        <div class="mx-4 my-2 md:mx-4 text-base font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <div class="flex" v-if="form.desativado">
                    <label for="naoContemAvaliacao" class="px-3 block text-sm font-medium my-3">
                        <input type="checkbox" v-model="form.ativo" class="rounded-sm" id="naoContemAvaliacao">
                        <span class="ml-2">
                            Ativada <span class="text-red-500">(marque essa opção e salve para reativar a atividade)</span>
                        </span>
                    </label>
                    <button
                        @click="excluirDefinitivamente"
                        type="button" 
                        class="text-white hover:bg-red-500 bg-red-700 text-sm rounded py-1 px-2 mx-1 my-1">
                        Excluir definitivamente
                    </button>
                </div>
                <ul class="flex flex-wrap -mb-px">
                    <li class="mr-2 cursor-pointer w-full md:w-40 2xl:w-64 border border-gry-200" @click="etapa = 1">
                        <a class="flex justify-center items-center py-4 md:py-2 px-1 h-full w-full text-sm_2 border-b-2 border-transparent" :class="etapa === 1 ? 'bg-gray-200 text-gray-800 border-yellow-300 hover:text-gray-800' : 'hover:text-gray-800 hover:border-yellow-300'">
                            1. Identificação do incidente
                        </a>
                    </li>
                    <li class="mr-2 cursor-pointer w-full md:w-40 2xl:w-64 border border-gry-200" @click="etapa = 2">
                        <a class="flex justify-center items-center py-4 md:py-2 px-1 h-full w-full text-sm_2 border-b-2 border-transparent" :class="etapa === 2 ? 'bg-gray-200 text-gray-800 border-yellow-300 hover:text-gray-800' : 'hover:text-gray-800 hover:border-yellow-300'">
                            2. Detalhes do incidente
                        </a>
                    </li>
                    <li class="mr-2 cursor-pointer w-full md:w-40 2xl:w-64 border border-gry-200" @click="etapa = 3">
                        <a class="flex justify-center items-center py-4 md:py-2 px-1 h-full w-full text-sm_2 border-b-2 border-transparent" :class="etapa === 3 ? 'bg-gray-200 text-gray-800 border-yellow-300 hover:text-gray-800' : 'hover:text-gray-800 hover:border-yellow-300'">
                            3. Impactos do incidente e Medidas de contenção
                        </a>
                    </li>
                    <li class="mr-2 cursor-pointer w-full md:w-40 2xl:w-64 border border-gry-200" @click="etapa = 4">
                        <a class="flex justify-center items-center py-4 md:py-2 px-1 h-full w-full text-sm_2 border-b-2 border-transparent" :class="etapa === 4 ? 'bg-gray-200 text-gray-800 border-yellow-300 hover:text-gray-800' : 'hover:text-gray-800 hover:border-yellow-300'">
                            4. Comunicação
                        </a>
                    </li>
                    <li class="mr-2 cursor-pointer w-full md:w-40 2xl:w-64 border border-gry-200" @click="etapa = 5">
                        <a class="flex justify-center items-center py-4 md:py-2 px-1 h-full w-full text-sm_2 border-b-2 border-transparent" :class="etapa === 5 ? 'bg-gray-200 text-gray-800 border-yellow-300 hover:text-gray-800' : 'hover:text-gray-800 hover:border-yellow-300'">
                            Registro de lições assimiladas
                        </a>
                    </li>
                </ul>
        </div>
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form v-show="etapa === 1">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-4">
                            <label for="titulo" class="block text-sm font-medium">
                                <b>1.1</b> Declaração de tipo de organização
                                <svg v-tooltip="`${tooltip['1.1']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <label for="microEmpresa" class="block text-sm font-medium my-3">
                                <input type="checkbox" v-model="form.microEmpresa" class="rounded-sm" id="microEmpresa">
                                <span class="ml-2">
                                    Declara ser Microempresa ou Empresa de Pequeno Porte 
                                </span>
                            </label>
                            <label for="agenteTratamentoPequeno" class="block text-sm font-medium my-3">
                                <input type="checkbox" v-model="form.agenteTratamentoPequeno" class="rounded-sm" id="agenteTratamentoPequeno">
                                <span class="ml-2">
                                    Declara ser Agente de Tratamento de Pequeno Porte
                                </span>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="titulo" class="block text-sm font-medium">
                                <b>1.2</b> Indique o tipo de comunicação
                            </label>
                            <label for="tipoComunicao-Completa" class="block mt-1">
                                <input type="radio" class="form-radio" @change="changeComunicacao()" for="tipoComunicao-Completa" name="tipoComunicao" v-model="form.tipoComunicao" value="Completa">
                                <span class="ml-2 text-sm">
                                    Completa 
                                    <svg v-tooltip="`${tooltip['1.2-Completa']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            </label>
                            <label for="tipoComunicao-Preliminar" class="block">
                                <input type="radio" class="form-radio" @change="changeComunicacao()" for="tipoComunicao-Preliminar" name="tipoComunicao" v-model="form.tipoComunicao" value="Preliminar">
                                <span class="ml-2 text-sm">
                                    Preliminar
                                    <svg v-tooltip="`${tooltip['1.2-Preliminar']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            </label>
                            <label for="tipoComunicao-Complementar" class="block">
                                <input type="radio" class="form-radio" @change="changeComunicacao()" for="tipoComunicao-Complementar" name="tipoComunicao" v-model="form.tipoComunicao" value="Complementar">
                                <span class="ml-2 text-sm">
                                    Complementar
                                    <svg v-tooltip="`${tooltip['1.2-Complementar']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="titulo" class="block text-sm font-medium">
                                <b>1.3</b> Dados do Notificante / Representante Legal
                                <svg v-tooltip="`${tooltip['1.3']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <label for="notificante-DPO" class="block mt-1">
                                <input type="radio" class="form-radio" for="notificante-DPO" name="notificante" v-model="form.notificante" value="DPO">
                                <span class="ml-3 text-sm">O próprio encarregado pela proteção de dados</span>
                            </label>
                            <label for="notificante-Outro" class="block">
                                <input type="radio" class="form-radio" for="notificante-Outro" name="notificante" v-model="form.notificante" value="Outro">
                                <span class="ml-3 text-sm">Outro (especifique)</span>
                            </label>
                            <div v-if="form.notificante === 'Outro'">
                                <label for="dadosNotificanteNome" class="block text-sm_2 font-medium">Nome</label>
                                <input type="text" v-model="form.dadosNotificante.nome" name="dadosNotificanteNome" id="dadosNotificanteNome" class="h-9 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                <label for="dadosNotificanteCpfCnpj" class="block text-sm_2 font-medium mt-1">CPF / CNPJ</label>
                                <input type="text" v-model="form.dadosNotificante.cpfCnpj" name="dadosNotificanteCpfCnpj" id="dadosNotificanteCpfCnpj" class="h-9 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                <label for="dadosNotificanteTelefone" class="block text-sm_2 font-medium mt-1">Telefone</label>
                                <input type="text" v-model="form.dadosNotificante.telefone" name="dadosNotificanteTelefone" id="dadosNotificanteTelefone" class="h-9 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                <label for="dadosNotificanteEmail" class="block text-sm_2 font-medium mt-1">Email</label>
                                <input type="text" v-model="form.dadosNotificante.email" name="dadosNotificanteEmail" id="dadosNotificanteEmail" class="h-9 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-4">
                            <label for="titulo" class="block text-sm font-medium">
                                <b>1.4</b> Título
                                <svg v-tooltip="`${tooltip['1.4']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <input type="text" v-model="form.titulo" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="status" class="block text-sm font-medium">
                                <b>1.5</b> Status
                                <svg v-tooltip="`${tooltip['1.5']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.status" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Pendente">Pendente</option>
                                <option value="Em análise">Em análise</option>
                                <option value="Concluído">Concluído</option>
                            </select>
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="tipoOnde" class="block text-sm font-medium">
                                <b>1.6</b> Onde aconteceu (fonte)
                                <svg v-tooltip="`${tooltip['1.6']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.tipoOnde" name="tipoOnde" id="tipoOnde" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Própria empresa">Própria empresa</option>
                                <option value="Terceiro">Terceiro (operador)</option>
                            </select>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5"> 
                        <div v-if="form.tipoOnde === 'Própria empresa'" class="col-span-12 md:col-span-4">
                            <label for="ondeSetor" class="block text-sm font-medium">
                                <b>1.7</b> Informe o setor da Empresa/Organização
                                <svg v-tooltip="`${tooltip['1.7']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.ondeSetor" name="ondeSetor" id="ondeSetor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                            </select>
                        </div>
                        <div v-if="form.tipoOnde === 'Terceiro'" class="col-span-12 md:col-span-4">
                            <label for="ondeFornecedor" class="block text-sm font-medium">
                                <b>1.7</b> Informe o agentes de tratamento
                                <svg v-tooltip="`${tooltip['1.7']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.ondeFornecedor" name="ondeFornecedor" id="ondeFornecedor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
                            </select>
                        </div> 
                        <div v-if="form.tipoOnde === 'Própria empresa'" class="col-span-12 md:col-span-4">
                            <label for="outrosSetores" class="block text-sm font-medium">
                                <b>1.8</b> Outros setores envolvidos
                                <svg v-tooltip="`${tooltip['1.8']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.outrosSetores" :options="setores" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="outrosSetores" placeholder="selecione os setores" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                        </div>
                        <div v-if="form.tipoOnde === 'Terceiro'" class="col-span-12 md:col-span-4">
                            <label for="outrosFornecedor" class="block text-sm font-medium">
                                <b>1.8</b> Outros agentes envolvidos
                                <svg v-tooltip="`${tooltip['1.8']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.outrosFornecedor" :options="fornecedores" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="outrosSetores" placeholder="selecione os agentes de tratamento" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="quemInformou" class="block text-sm font-medium">
                                <b>1.9</b> Quem informou
                                <svg v-tooltip="`${tooltip['1.9']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <label for="quemInformou" class="mt-1 block text-sm_2 font-medium">Nome</label>
                            <input type="text" v-model="form.quemInformou" name="quemInformou" id="quemInformou" class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            <label for="quemInformouCpfCNPJ" class="mt-2 block text-sm_2 font-medium">CPF / CNPJ</label>
                            <input type="text" v-model="form.quemInformouCpfCNPJ" name="quemInformouCpfCNPJ" id="quemInformouCpfCNPJ" class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            <label for="quemInformouEmail" class="mt-2 block text-sm_2 font-medium">Email</label>
                            <input type="text" v-model="form.quemInformouEmail" name="quemInformouEmail" id="quemInformouCpfCNPJ" class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4">
                            <label for="grauDeRisco" class="block text-sm font-medium">
                                <b>1.10</b> Grau de risco preliminar
                                <svg v-tooltip="`${tooltip['1.10']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.grauDeRisco" name="grauDeRisco" id="grauDeRisco" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="muitobaixa">Muito baixo</option>
                                <option value="baixo">Baixo</option>
                                <option value="medio">Médio</option>
                                <option value="alto">Alto</option>
                                <option value="muitoalto">Muito Alto</option>
                            </select>
                        </div> 
                        <div class="col-span-12 md:col-span-8 2xl:col-span-5">
                            <label for="avaliacaoRisco" class="block text-sm font-medium">
                                <b>1.11</b> Avaliação preliminar (avaliação risco)
                                <svg v-tooltip="`${tooltip['1.11']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.avaliacaoRisco" name="avaliacaoRisco" id="avaliacaoRisco" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Risco do incidente aos titulares ainda está sendo apurado">Risco do incidente aos titulares ainda está sendo apurado</option>
                                <option value="O incidente de segurança pode acarretar risco ou dano relevante aos titulares">O incidente de segurança pode acarretar risco ou dano relevante aos titulares</option>
                                <option value="O incidente não acarretou risco ou dano relevante aos titulares"> O incidente não acarretou risco ou dano relevante aos titulares</option>
                            </select>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4">
                            <label for="justificativaAvaliacao" class="block text-sm font-medium">
                                <b>1.12</b> Justifique, se cabível, a avaliação do risco do incidente
                                <svg v-tooltip="`${tooltip['1.12']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <textarea rows="3" type="text" v-model="form.justificativaAvaliacao" name="justificativaAvaliacao" id="justificativaAvaliacao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="motivos" class="block text-sm font-medium">
                                <b>1.13</b> Em caso de demora no registro, exponha os motivos
                                <svg v-tooltip="`${tooltip['1.13']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <textarea rows="3" type="text" v-model="form.motivos" name="motivos" id="motivos" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="comunicadoAutoridade" class="block text-sm font-medium">
                                <b>1.14</b> O incidente foi comunicado a alguma autoridade, quais e quando
                                <svg v-tooltip="`${tooltip['1.14']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <textarea rows="3" type="text" v-model="form.comunicadoAutoridade" name="comunicadoAutoridade" id="comunicadoAutoridade" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div> 
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4">
                            <label for="quandoOcorreu" class="block text-sm font-medium">
                                <b>1.15</b> Quando aconteceu (ou suspeita)
                                <svg v-tooltip="`${tooltip['1.15']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <input type="datetime-local" v-model="form.quandoOcorreu" name="quandoOcorreu" id="quandoOcorreu" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="quandoDescobrimos" class="block text-sm font-medium">
                                <b>1.16</b> Quando descobrimos 
                                <svg v-tooltip="`${tooltip['1.16']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <input type="datetime-local" v-model="form.quandoDescobrimos" name="quandoDescobrimos" id="quandoDescobrimos" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="quandoRegistramos" class="block text-sm font-medium">
                                <b>1.17</b> Quando registramos 
                                <svg v-tooltip="`${tooltip['1.17']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <input type="datetime-local" v-model="form.quandoRegistramos" name="quandoRegistramos" id="quandoRegistramos" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                </form>
                <form v-show="etapa === 2">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-4">
                            <label for="classificacaoAfetado" class="block text-sm font-medium">
                                <b>2.1</b> O que foi afetado com o incidente (Classificação)
                                <svg v-tooltip="`${tooltip['2.1']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.classificacaoAfetado" name="classificacaoAfetado" id="classificacaoAfetado" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Confidencialidade">Confidencialidade</option>
                                <option value="Integridade">Integridade</option>
                                <option value="Disponibilidade">Disponibilidade</option>
                            </select>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="atividade" class="block text-sm font-medium">
                                <b>2.2</b> Atrelar incidente a uma atividade mapeada
                                <svg v-tooltip="`${tooltip['2.2']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.atividade" :options="atividades" @input="onSelectAtividade" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="atividade" placeholder="" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2 h-10" />
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="tipotitular" class="block text-sm font-medium">
                                <b>2.3</b> Tipo de titulares envolvidos (categoria)
                                <svg v-tooltip="`${tooltip['2.3']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.tipotitular" :options="tipotitulares" @input="onSelectTipotitular" :multiple="true" :close-on-select="false" :taggable="true" label="tipo" trackBy="_id" id="dados" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                            <div v-if="form.qtdTipotitular" class="overflow-auto mt-2">
                                <div class="py-2 align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Categoria
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Quantidade
                                                </th>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="item in form.qtdTipotitular" :key="item._id">
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.tipo && item.tipo.tipo ? item.tipo.tipo : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                <input v-model="item.qtd" class="border-b border-gray-300 px-5 ring-0 focus:ring-0 focus:border-none outline-0 focos:outline-0" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4">
                            <label for="titularmenor" class="block text-sm font-medium">
                                <b>2.4</b> Os titulares são menores de 18 ou vulneráveis
                                <svg v-tooltip="`${tooltip['2.4']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.titularmenor" name="titularmenor" id="titularmenor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option :value="false" default>Não</option>
                                <option :value="true">Sim</option>
                            </select>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="volumeDeDados" class="block text-sm font-medium">
                                <b>2.5</b> Volume de dados envolvidos
                                <svg v-tooltip="`${tooltip['2.5']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <input type="text" v-model="form.volumeDeDados" name="volumeDeDados" id="volumeDeDados" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="qtdTitulares" class="block text-sm font-medium">
                                <b>2.6</b> Quantidade de titulares envolvidos
                                <svg v-tooltip="`${tooltip['2.6']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <input type="number" v-model="form.qtdTitulares" name="qtdTitulares" id="qtdTitulares" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-3">
                            <label for="possuiEvidencia" class="block text-sm font-medium">
                                <b>2.7</b> Possui alguma evidência do incidente?
                                <svg v-tooltip="`${tooltip['2.7']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.possuiEvidencia" name="possuiEvidencia" id="possuiEvidencia" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option :value="false" default>Não</option>
                                <option :value="true">Sim</option>
                            </select>
                        </div>
                        <div v-if="form.possuiEvidencia" class="col-span-12 md:col-span-3">
                            <label class="block text-sm font-medium mb-1">Arquivo</label>
                            <vue-dropzone ref="evidencias" id="evidencias" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateEvidencias">
                            </vue-dropzone>
                            <label class="block text-sm font-medium my-1">URL</label>
                            <div class="relative">
                                <input type="text" v-model="link" id="search" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" placeholder="Link">
                                <button @click="addLink" type="button" class="text-white absolute right-2 bottom-1 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm_2 px-4 py-2">
                                    Adicionar
                                </button>
                            </div>
                        </div>
                        <div v-if="form.possuiEvidencia" class="col-span-12 md:col-span-6">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            ID
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Arquivo / Link
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(evidencia, index) in form.evidencias" :key="index">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                {{ index }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div v-if="evidencia.mimetype" class="text-sm text-gray-500">
                                                    <a target="_blank" :href="`${url_api}/upload?mimetype=${evidencia.mimetype}&filename=${evidencia.filename}&folder=empresas`">{{ evidencia.originalname }}</a>
                                                </div>
                                                <p v-else class="text-sm text-gray-500">{{evidencia.link}}</p>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                <button @click="form.evidencias.splice(index, 1)" type="button" class="text-white hover:bg-red-500 bg-red-400 rounded-lg py-2 px-3">Remover</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-5 2xl:col-span-4">
                            <label for="possuiRIPD" class="block text-sm font-medium">
                                <b>2.8</b> Foi elaborado um Relatório de Impacto à Proteção de Dados Pessoais (RIPD) das atividades de tratamento afetadas pelo incidente?
                                <svg v-tooltip="`${tooltip['2.8']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.possuiRIPD" name="possuiRIPD" id="possuiRIPD" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option :value="false" default>Não</option>
                                <option :value="true">Sim</option>
                            </select>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4">
                            <label for="dados" class="block text-sm font-medium">
                                <b>2.9</b> Tipo de dados pessoais afetados
                                <svg v-tooltip="`${tooltip['2.9']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.dados" :options="dados" :multiple="true" :close-on-select="false" @input="onSelect" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dados" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="categoriadados" class="block text-sm font-medium">
                                <b>2.10</b> Categoria dos dados pessoais afetados
                                <svg v-tooltip="`${tooltip['2.10']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.categoriadados" :options="categorias" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="atividade" placeholder="Selecione as categorias de dados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="dadossensiveis" class="block text-sm font-medium">
                                <b>2.11</b> Entre os dados afetados, quais são sensíveis
                                <svg v-tooltip="`${tooltip['2.11']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <multiselect v-model="form.dadossensiveis" :options="dadossensiveis" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="dadossensiveis" placeholder="selecione os dados sensíveis" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-5">
                        <div class="col-span-12 md:col-span-4">
                            <label for="descoberto" class="block text-sm font-medium">
                                <b>2.12</b> Descreva como e por que ocorreu o incidente (identifique a causa raiz, se conhecida)
                                <svg v-tooltip="`${tooltip['2.12']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <textarea rows="3" type="text" v-model="form.descoberto" name="descoberto" id="descoberto" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                        <div class="col-span-12 md:col-span-8">
                            <label for="descoberto" class="block text-sm font-medium mb-4">
                                <b>2.13</b> Qual o tipo de incidente? (Informe os tipos mais específicos)
                                <svg v-tooltip="`${tooltip['2.13']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <div class="grid grid-cols-12 gap-2">
                                <div class="col-span-12 md:col-span-6">
                                    <label for="ransomwareSemTransferencia" class="block text-sm font-medium mb-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.ransomwareSemTransferencia" class="rounded-sm" id="ransomwareSemTransferencia">
                                        <span class="ml-2">
                                            Sequestro de dados (ransomware) sem transfência de informações
                                        </span>
                                    </label>
                                    <label for="vulnerabilidade" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.vulnerabilidade" class="rounded-sm" id="vulnerabilidade">
                                        <span class="ml-2">
                                            Exploração de vulnerabilidade em sistemas de informação
                                        </span>
                                    </label>
                                    <label for="engenhariaSocial" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.engenhariaSocial" class="rounded-sm" id="engenhariaSocial">
                                        <span class="ml-2">
                                            Roubo de credenciais / Engenharia Social.
                                        </span>
                                    </label>
                                    <label for="publicacaoDados" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.publicacaoDados" class="rounded-sm" id="publicacaoDados">
                                        <span class="ml-2">
                                            Publicação não intencional de dados pessoais
                                        </span>
                                    </label>
                                    <label for="destinarioIncorreto" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.destinarioIncorreto" class="rounded-sm" id="destinarioIncorreto">
                                        <span class="ml-2">
                                            Envio de dados a destinatário incorreto
                                        </span>
                                    </label>
                                    <label for="Dos" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.Dos" class="rounded-sm" id="Dos">
                                        <span class="ml-2">
                                            Negação de Serviço (DoS)
                                        </span>
                                    </label>
                                    <label for="rouboDocumentos" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.rouboDocumentos" class="rounded-sm" id="rouboDocumentos">
                                        <span class="ml-2">
                                            Perda/roubo de documentos ou dispositivos eletrônicos
                                        </span>
                                    </label>
                                    <label for="falhaHardware" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.falhaHardware" class="rounded-sm" id="falhaHardware">
                                        <span class="ml-2">
                                            Falha em equipamento (hardware)
                                        </span>
                                    </label>
                                    <label for="outroCibernetico" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.outroCibernetico" class="rounded-sm" id="outroCibernetico">
                                        <span class="ml-2">
                                            Outro tipo de incidente cibernético
                                        </span>
                                    </label>
                                </div>
                                <div class="col-span-12 md:col-span-6">
                                    <label for="ransomwareComTransferencia" class="block text-sm font-medium mb-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.ransomwareComTransferencia" class="rounded-sm" id="ransomwareComTransferencia">
                                        <span class="ml-2">
                                            Sequestro de dados (ransomware) com transferência e/ou publicação de informações
                                        </span>
                                    </label>
                                    <label for="virus" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.virus" class="rounded-sm" id="virus">
                                        <span class="ml-2">
                                            Vírus de Computador / Malware
                                        </span>
                                    </label>
                                    <label for="brutalforce" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.brutalforce" class="rounded-sm" id="brutalforce">
                                        <span class="ml-2">
                                            Violação de credencial por força bruta
                                        </span>
                                    </label>
                                    <label for="divulgacaoDados" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.divulgacaoDados" class="rounded-sm" id="divulgacaoDados">
                                        <span class="ml-2">
                                            Divulgação indevida de dados pessoais
                                        </span>
                                    </label>
                                    <label for="acessoNaoAutorizado" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.acessoNaoAutorizado" class="rounded-sm" id="acessoNaoAutorizado">
                                        <span class="ml-2">
                                            Acesso não autorizado a sistemas de informação
                                        </span>
                                    </label>
                                    <label for="alteracaoDados" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.alteracaoDados" class="rounded-sm" id="alteracaoDados">
                                        <span class="ml-2">
                                            Alteração/exclusão não autorizada de dados
                                        </span>
                                    </label>
                                    <label for="descarteIncorreto" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.descarteIncorreto" class="rounded-sm" id="descarteIncorreto">
                                        <span class="ml-2">
                                            Descarte incorreto de documentos ou dispositivos eletrônicos
                                        </span>
                                    </label>
                                    <label for="falhaSoftware" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.falhaSoftware" class="rounded-sm" id="falhaSoftware">
                                        <span class="ml-2">
                                            Falha em sistema de informação (software)
                                        </span>
                                    </label>
                                    <label for="outroNaoCibernetico" class="block text-sm font-medium my-2">
                                        <input type="checkbox" v-model="form.tipoIncidente.outroNaoCibernetico" class="rounded-sm" id="outroNaoCibernetico">
                                        <span class="ml-2">
                                            Outro tipo de incidente não cibernético
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form v-show="etapa === 3">
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-6 2xl:col-span-4">
                            <label for="impacto_titular" class="block text-sm font-medium">
                                <b>3.1</b> Qual é o impacto do incidente para os <b>titulares</b> afetados (ou quais os possíveis impactos) ?
                                <svg v-tooltip="`${tooltip['3.1']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <multiselect v-model="form.impacto_titular" :options="impactos_titular" :multiple="true" :close-on-select="false" label="descricao" trackBy="_id" id="impacto_titular" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4 md:col-span-6 2xl:col-span-4">
                            <label for="impacto_negocio" class="block text-sm font-medium">
                                <b>3.2</b> Qual é o impacto do incidente para os <b>negócios</b> da organização/empresa (ou os possíveis impactos) ?
                                <svg v-tooltip="`${tooltip['3.2']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4 md:col-span-6">
                            <multiselect v-model="form.impacto_negocio" :options="impactos_negocio" :multiple="true" :close-on-select="false" label="descricao" trackBy="_id" id="impacto_titular" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4 md:col-span-6 2xl:col-span-4">
                            <label for="impacto_terceiros" class="block text-sm font-medium">
                                <b>3.3</b> Qual é o impacto do incidente para <b>terceiros</b> envolvidos (ou os possíveis impactos) ?
                                <svg v-tooltip="`${tooltip['3.3']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4 md:col-span-6">
                            <textarea rows="2" type="text" v-model="form.impacto_terceiros" placeholder="Quais os impactos?" name="impacto_terceiros" id="impacto_terceiros" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4 md:col-span-6 2xl:col-span-4">
                            <label for="medidasContencao" class="block text-sm font-medium">
                                <b>3.4</b> Indique as medidas de segurança utilizadas para a <b>contenção</b> (mitigação) dos dados (Ativos) envolvidos no inciente
                                <svg v-tooltip="`${tooltip['3.4']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4 md:col-span-6">
                            <textarea rows="2" type="text" v-model="form.medidasContencao" placeholder="Medidas de contenção" name="medidasContencao" id="medidasContencao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4 md:col-span-6 2xl:col-span-4">
                            <label for="medidasErradicacao" class="block text-sm font-medium">
                                <b>3.5</b> Indique as medidas de segurança utilizadas para a <b>erradicação</b> do problema (ameaça) que ocasionou o incidente 
                                <svg v-tooltip="`${tooltip['3.5']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4 md:col-span-6">
                            <textarea rows="2" type="text" v-model="form.medidasErradicacao" placeholder="Medidas para erradicação" name="medidasErradicacao" id="medidasErradicacao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4 md:col-span-6 2xl:col-span-4">
                            <label for="medidasRecuperacao" class="block text-sm font-medium">
                                <b>3.6</b> Indique as medidas de segurança utilizadas para a <b>recuperação</b> dos ativos envolvidos 
                                <svg v-tooltip="`${tooltip['3.6']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4 md:col-span-6">
                            <textarea rows="2" type="text" v-model="form.medidasRecuperacao" placeholder="Medidas para recuperação" name="medidasRecuperacao" id="medidasRecuperacao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-12 md:col-span-4 md:col-span-6 2xl:col-span-4">
                            <label for="medidasRecuperacao" class="block text-sm font-medium">
                                <b>3.7</b> Indique foi adotada alguma <b>nova</b> medida de segurança 
                                <svg v-tooltip="`${tooltip['3.7']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                        </div>
                        <div class="col-span-12 md:col-span-4 md:col-span-6">
                            <textarea rows="2" type="text" v-model="form.medidasNova" placeholder="Novas medidas" name="medidasNova" id="medidaNova" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                    </div>
        
                </form>
                <form v-show="etapa === 4">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-3">
                            <label for="titularJaComunicado" class="block text-sm font-medium">
                                <b>4.1</b> Os titulares já foram comunicados sobre o incidente
                                <svg v-tooltip="`${tooltip['4.1']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="form.titularJaComunicado" name="titularJaComunicado" id="titularJaComunicado" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option :value="false" default>Não</option>
                                <option :value="true">Sim</option>
                            </select>
                        </div>
                        <div v-if="form.titularJaComunicado" class="col-span-12 md:col-span-3">
                            <label for="quandoFoiComunicado" class="block text-sm font-medium">
                                Quando foi comunicado
                            </label>
                            <input type="datetime-local" v-model="form.quandoFoiComunicado" name="quandoFoiComunicado" id="quandoFoiComunicado" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div v-if="form.titularJaComunicado" class="col-span-12 md:col-span-4">
                            <label for="formaComunicado" class="block text-sm font-medium">
                                De que forma a ocorrência do incidente foi comunicada aos titulares
                            </label>
                            <select v-model="form.formaComunicado" name="formaComunicado" id="formaComunicado" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Comunicado individual por escrito (carta/e-mail/etc.)">Comunicado individual por escrito (mensagem eletrônica / carta / e-mail/ etc.)</option>
                                <option value="Comunicado individual por escrito com confirmação de recebimento">Comunicado individual por escrito com confirmação de recebimento</option>
                                <option value="Anúncio público no sítio eletrônico, mídias sociais ou aplicativos do controlador">Anúncio público no sítio eletrônico, mídias sociais ou aplicativos do controlador</option>
                                <option value="Ampla divulgação do fato em meios de comunicação, por iniciativa do controlador">Ampla divulgação do fato em meios de comunicação, por iniciativa do controlador</option>
                                <option value="Outra">Outra</option>
                            </select>
                            <input v-if="form.formaComunicado === 'Outra'" placeholder="Forma que o incidente foi comunicado" type="text" v-model="form.outraForma" name="outraForma" id="outraForma" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-5">
                        <div class="col-span-12 md:col-span-5">
                            <label for="impacto_titular" class="block text-sm font-medium">
                                <b>4.2</b> Selecione <b>quem</b> você deseja comunicar sobre o incidente ocorrido
                                <svg v-tooltip="`${tooltip['4.2']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <select v-model="buscaParms.tipo" name="buscaParms" id="buscaParms" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Titular">Titulares de dados</option>
                                <option value="Fornecedor">Agentes de tratamento</option>
                                <option value="Outros">Outros</option>
                            </select>
                            <div v-if="buscaParms.tipo !== 'Outros'" class="grid grid-cols-12 gap-2 mt-4">
                                <div v-if="buscaParms.tipo === 'Titular'" class="col-span-12 md:col-span-9">
                                    <label class="block text-sm font-medium">
                                        <b>4.2</b> Selecione o <b>perfil</b> de titulares que deseja comunicar o incidente
                                        <svg v-tooltip="`${tooltip['3.1']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </label>
                                    <select v-model="buscaParms.tipoTitular" name="tipoTitular" id="tipoTitular" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option :value="null">Todos os perfils</option>
                                        <option v-for="tipo in tipotitulares" :key="tipo._id" :value="tipo._id">{{tipo.tipo}}</option>
                                    </select>
                                </div>
                                <div v-if="buscaParms.tipo === 'Fornecedor'" class="col-span-12 md:col-span-9">
                                    <label class="block text-sm font-medium">
                                        <b>4.3</b> Selecione a <b>categoria</b> de agentes que deseja comunicar o incidente
                                        <svg v-tooltip="`${tooltip['4.3']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </label>
                                    <select v-model="buscaParms.agente" name="tipoTitular" id="tipoTitular" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option :value="null">Todos as categoria</option>
                                        <option v-for="a in agentestratamento" :key="a._id" :value="a._id">{{a.nome}}</option>
                                    </select>
                                </div>
                                <div class="col-span-12 md:col-span-3">
                                    <label class="block text-sm font-medium">Buscar</label>
                                    <button @click="startBusca()" type="button" class="inline-flex justify-center w-full py-2 px-2 mt-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                            <div v-if="buscaParms.tipo !== 'Outros'" class="mt-2">
                                <span class="font-semibold text-sm">Total: {{total}} <span @click="selecionarTodos()" class="text-green-700 underline cursor-pointer ml-1">Selecionar todos</span> <span class="text-blue-700 ml-1">Selecionados: {{selecionadosBusca.length}}</span></span>
                            </div>
                            <div v-if="busca.length && buscaParms.tipo !== 'Outros'" class="h-96 overflow-auto px-3 py-2 bg-gray-200 rounded-lg mt-2">
                                <label v-for="item in busca" :key="item._id" class="block text-sm font-medium my-1">
                                    <input type="checkbox" v-model="item.check" @change="changeBusca(item)" class="rounded-sm" :id="item._id">
                                    <span class="ml-2">
                                        {{ item.nome }}<span v-if="item.email"> - {{ item.email }}</span>
                                    </span>
                                </label>
                            </div>
                            <button v-if="total > 0 && buscaParms.tipo !== 'Outros'" @click="addConvidados()" type="button" class="inline-flex justify-center py-1 px-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                                Adicionar destinatários
                            </button>
                            <div v-if="buscaParms.tipo === 'Outros'" class="mt-2">
                                <label for="outroNome" class="block text-sm font-medium"> Nome </label>
                                <input type="text" v-model="outro.nome" name="outroNome" id="outroNome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                <label for="outroEmail" class="block text-sm font-medium mt-2"> Email </label>
                                <input type="text" v-model="outro.email" name="outroEmail" id="outroNome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <button v-if="buscaParms.tipo === 'Outros'" @click="addConvidado()" type="button" class="inline-flex justify-center py-1 px-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                                Adicionar destinatário
                            </button>
                        </div>
                        <div class="col-span-12 md:col-span-7">
                            <h3 class="text-lg font-medium">Comunicar destinatários</h3>
                            <button @click="$modal.show('importarModal')" type="button" class="my-1 bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-56 font-semibold justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg> 
                                Importar base 
                            </button>
                            <span class="font-semibold text-green-600 text-sm">Total: {{totalConvidados}} </span><span @click="removerConvidados" v-if="selecionadosConvidados.length" class="text-sm font-semibold text-red-700 underline cursor-pointer ml-1">Remover selecionados</span> <span v-if="selecionadosConvidados.length" class="text-sm font-semibold text-blue-700 ml-1">Selecionados: {{selecionadosConvidados.length}}</span>
                            <div v-if="convidados.length" class="h-120 overflow-auto mt-2">
                                <div class="py-2 align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                                                        <svg @click="selecionarTodosConvidados()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        </svg>
                                                    </th>
                                                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Nome
                                                    </th>
                                                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Email
                                                    </th>
                                                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Categoria titular / perfil
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="item in convidados" :key="item._id">
                                                    <td v-if="!$store.state.user.cliente" class="px-1 py-2">
                                                        <div class="flex items-center">
                                                            <input :checked="selecionadosConvidados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.nome ? item.nome : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.email ? item.email : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.categoria ? item.categoria : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <h4 v-else class="text-base">Nenhum selecionado para comunicar</h4>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-5">
                        <div class="col-span-12">
                            <label for="textoComunicao" class="block text-sm font-medium mb-2">
                                <b>4.2</b> Digite aqui o <b>texto</b> para que seja enviado por email para os destinatários
                                <svg v-tooltip="`${tooltip['3.1']}`" xmlns="http://www.w3.org/2000/svg" class="h-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </label>
                            <VueEditor v-model="form.textoComunicao" name="textoComunicao" id="textoComunicao"></VueEditor>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-3">
                        <div class="col-span-12 md:col-span-12">
                            <label for="tituloComunicao" class="block text-sm font-medium my-2">Título comunicado</label>
                            <input v-model="form.tituloComunicao" type="text" name="tituloComunicao" id="tituloComunicao" class="w-full focus:ring-yellow-400 focus:border-yellow-400 block shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="anexos" class="block text-sm font-medium my-2">Anexos comunicado</label>
                            <vue-dropzone ref="anexos" id="anexos" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateAnexos">
                            </vue-dropzone>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            ID
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Arquivo / Link
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(anexo, index) in form.anexos" :key="index">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                {{ index }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div v-if="anexo.mimetype" class="text-sm text-gray-500">
                                                    <a target="_blank" :href="`${url_api}/upload?mimetype=${anexo.mimetype}&filename=${anexo.filename}&folder=empresas`">{{ anexo.originalname }}</a>
                                                </div>
                                                <p v-else class="text-sm text-gray-500">{{anexo.link}}</p>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                <button @click="form.anexos.splice(index, 1)" type="button" class="text-white hover:bg-red-500 bg-red-400 rounded-lg py-2 px-3">Remover</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-3">
                        <div class="col-span-12">
                            <button @click="comunicarIncidente" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                                </svg>
                                Comunicar incidente
                            </button>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-4">
                        <div class="col-span-12 md:col-span-3">
                            <label for="quandoFoiComunicado" class="block text-sm font-medium">
                                Quando foi comunicado à ANPD
                            </label>
                            <input type="datetime-local" v-model="form.quandoFoiComunicadoANPD" name="quandoFoiComunicado" id="quandoFoiComunicado" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                </form>
                <form v-show="etapa === 5">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-4">
                            <label for="impacto_titular" class="block text-sm font-medium">Participantes da reunião</label>
                            <multiselect v-model="form.presentesNaEmpresa" :options="pessoas" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="convidados" placeholder="selecione os convidados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />  
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="outroParticipante" class="block text-sm font-medium">Outro Participante da reunião</label>
                            <input type="text" v-model="outroParticipante.nome" placeholder="Nome" name="outroParticipanteNome" id="outroParticipanteNome" class="h-9 my-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            <input type="text" v-model="outroParticipante.email" placeholder="Email" name="outroParticipanteEmail" id="outroParticipanteEmail" class="h-9 my-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            <button @click="addParticipante()" type="button" class="block justify-center py-1 px-2 mt-2 mb-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                                Adicionar participante
                            </button>
                            <span class="font-semibold text-green-600 text-sm">Total: {{form.outrosParticipante && form.outrosParticipante.length || 0}} </span><span @click="removerParticipantes" v-if="selecionadosParticipantes.length" class="text-sm font-semibold text-red-700 underline cursor-pointer ml-1">Remover selecionados</span> <span v-if="selecionadosParticipantes.length" class="text-sm font-semibold text-blue-700 ml-1">Selecionados: {{selecionadosParticipantes.length}}</span>
                            <div class="overflow-auto">
                                <div class="py-2 align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                                                    <svg @click="selecionarTodosParticipantes()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Nome
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Email
                                                </th>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="item in form.outrosParticipantes" :key="item._id">
                                                    <td class="px-1 py-2">
                                                        <div class="flex items-center">
                                                            <input :checked="selecionadosParticipantes.map(el => el.email).indexOf(item.email) > -1" @change="(e) => selecionadosControlParticipante(item, e)" type="checkbox" class="rounded-sm">
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.nome ? item.nome : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.email ? item.email : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="dataReuniao" class="block text-sm font-medium">Data e hora da realização</label>
                            <input type="datetime-local" v-model="form.dataReuniao" name="dataReuniao" id="dataReuniao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-3 mt-5">
                        <div class="col-span-12">
                            <label for="ataReuniao" class="block text-sm font-medium mb-2">Ata da reunião de lições aprendidas</label>
                            <textarea rows="4" type="text" v-model="form.ataReuniao" placeholder="Ata Reunião" name="ataReuniao" id="ataReuniao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                        </div>
                        <div class="col-span-12">
                            <a @click.prevent="saveTextAta()" target="_blank" rel="noreferrer" :href="`${url_api}/v1/incidentes/ataReuniao/${$route.params.id}?token=${$store.state.token}`" role="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                Gerar Ata DOCX
                            </a>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="col-span-4">
                            <label for="ataReuniaoDocumento" class="block text-sm font-medium mb-2">Ata da reunião em documento anexado</label>
                            <vue-dropzone ref="ataReuniaoDocumento" id="ataReuniaoDocumento" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateAtaReuniaoDocumento">
                            </vue-dropzone>
                            <div v-if="form.ataReuniaoDocumento && form.ataReuniaoDocumento.mimetype" class="text-sm underline text-gray-800 mt-3">
                                <a target="_blank" :href="`${url_api}/upload?mimetype=${form.ataReuniaoDocumento.mimetype}&filename=${form.ataReuniaoDocumento.filename}&folder=empresas`">{{ form.ataReuniaoDocumento.originalname }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-3">
                        <div class="col-span-12">
                            <button @click="enviarATA" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline mr-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                                </svg>
                                Enviar ATA participantes
                            </button>
                        </div>
                    </div>
                </form>
                <div class="grid grid-cols-2 mt-10">
                    <div class="py-3 text-left">
                        <button @click="back" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                        <button v-if="this.etapa < 4" @click="next()" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-600">
                            Próximo
                        </button>
                    </div>
                    <div v-if="etapa < 5" class="py-3 text-right">
                        <button @click="save()" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-600">
                            Salvar até aqui
                        </button>
                        <button v-if="this.etapa >= 4" @click="save('Concluído')" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                            Concluir registro 
                        </button>
                    </div>
                    <div v-else class="py-3 text-right">
                        <button @click="save()" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-600">
                            Concluir registro lições aprendidas
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal name="importarModal" :adaptive="true" :height="'auto'">
            <div class="px-5 py-5">
            <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12">
                    <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-18.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
                </div>
                <div class="col-span-12">
                    <vue-dropzone ref="destinatario" id="destinatario" class="w-full" :options="dropzoneOptionsDestinatario" @vdropzone-sending="send" v-on:vdropzone-success="updateDestinatario">
                    </vue-dropzone>
                </div>
            </div>
            </div>
        </modal>
    </div>
</template>

<script>
import moment from 'moment';
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'incidentes',
            etapa: 1,
            setores: [],
            pessoas: [],
            atividades: [],
            dados: [],
            dadossensiveis: [],
            categorias: [],
            tipotitulares: [],
            agentestratamento: [],
            impactos_titular: [],
            impactos_negocio: [],
            titulares: [],
            fornecedores: [],
            link: "",
            convidados: [],
            selecionadosConvidados: [],
            selecionadosParticipantes: [],
            totalConvidados: 0,
            busca: [],
            selecionadosBusca: [],
            total: 0,
            outro: { nome: "", email: "" },
            buscaParms: {
                tipo: "Titular",
                busca: "",
                tipoTitular: null,
                agente: null,
            },
            outroParticipante: { nome: "", email: "" },
            form: {
                // Identificar incidente
                microEmpresa: false,
                agenteTratamentoPequeno: false,
                tipoComunicao: "",
                notificante: "",
                dadosNotificante: {
                    nome: "",
                    cpfCnpj: "",
                    telefone: "",
                    email: "",
                },
                titulo: "",
                canal: "",
                status: "Pendente",
                tipoOnde: "Própria empresa",
                possuiRIPD: false,
                ondeSetor: null,
                ondeFornecedor: null,
                quemInformou: "",
                quemInformouCpfCNPJ: "",
                quemInformouEmail: "",
                grauDeRisco: "",
                avaliacaoRisco: "",
                justificativaAvaliacao: "",
                quandoOcorreu: null,
                quandoDescobrimos: null,
                quandoRegistramos: null,
                motivos: "",
                comunicadoAutoridade: "",
                // Detalhe incidente
                classificacaoAfetado: "",
                atividade: null,
                titularmenor: false,
                volumeDeDados: "",
                qtdTitulares: "",
                dados: [],
                categoriadados: [],
                dadossensiveis: [],
                tipotitular: [],
                qtdTipotitular: [],
                tipoIncidente: {
                    ransomwareSemTransferencia: false,
                    ransomwareComTransferencia: false,
                    vulnerabilidade: false,
                    engenhariaSocial: false,
                    publicacaoDados: false,
                    destinarioIncorreto: false,
                    Dos: false,
                    rouboDocumentos: false,
                    falhaHardware: false,
                    falhaSoftware: false,
                    outroCibernetico: false,
                    outroNaoCibernetico: false,
                    virus: false,
                    brutalforce: false,
                    divulgacaoDados: false,
                    acessoNaoAutorizado: false,
                    alteracaoDados: false,
                    descarteIncorreto: false,
                },
                // Impactos
                impacto_titular: [],
                impacto_negocio: [],
                impacto_terceiros: "",
                medidasContencao: "",
                medidasErradicacao: "",
                medidasRecuperacao: "",
                medidasNova: "",
                // Registro
                outrosParticipantes: [],
                presentesNaEmpresa: [],
                dataReuniao: null,
                ataReuniao: "",
                ataReuniaoDocumento: null,
                //
                titularJaComunicado: false,
                quandoFoiComunicado: null,
                quandoFoiComunicadoANPD: null,
                formaComunicado: "",
                outraForma: "",
                textoComunicao: "",
                tituloComunicao: "ATENÇÃO: Comunicado incidente !!",
                anexos: [],
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste aqui',
                dictRemoveFile: 'Remover'
            }),
            dropzoneOptionsDestinatario: this.$http.getDropzoneConfig(
                `v1/incidentes/importar-csv`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste sua evidência aqui',
                dictRemoveFile: 'Remover'
            }),
            tooltip: {
                '1.1': 'Declaração em caso de pequeno porte da organização',
                '1.2-Completa': 'Todas as informações a respeito do incidente estão disponíveis e a comunicação aos titulares já foi realizada.',
                '1.2-Preliminar': 'Nem todas as informações sobre o incidente estão disponíveis, justificadamente, ou a comunicação aos titulares ainda não foi realizada. A complementação deverá ser encaminhada em até 30 dias corridos da comunicação preliminar.',
                '1.2-Complementar': 'Complementação de informações prestadas em comunicação preliminar. A comunicação complementar deve ser protocolada no mesmo processo que a comunicação preliminar.',
                '1.3': 'Identificação do notificante do incidente',
                '1.4': 'Título para nomear o incidente ocorrido.',
                '1.5': 'Status do registro do incidente.',
                '1.6': 'Indique se o incidente ocorreu na empresa ou terceiro.',
                '1.7': 'Setor (Empresa) / Agente (Terceiro) onde ocorreu o incidente.',
                '1.8': 'Setor (Empresa) / Agente (Terceiro) que podem ter sido afetados também pelo incidente.',
                '1.9': 'Dados da pessoa(s) que relatou o incidente.',
                '1.10': 'Gravidadade do incidente.',
                '1.11': 'Avaliação do risco incidente.',
                '1.12': 'Justificativa / Avaliação do incidente.',
                '1.13': 'Justificativa da demora do registro.',
                '1.14': 'Informe quando e quais em caso de notificação a autoridade.',
                '1.15': 'Data de quando ocorreu ou data suspeita.',
                '1.16': 'Data de quando ficaram sabendo.',
                '1.17': 'Data de quando foi registrado o incidente.',
                '2.1': 'Classificação do incidente, referente ao que foi afetado.',
                '2.2': 'Indique a atividade vinculada ao incidente.',
                '2.3': 'Categoria referente ao tipo de titular afetado.',
                '2.4': 'Titular referente menor ou vulnerável.',
                '2.5': 'Volume de dados envolvido no incidente.',
                '2.6': 'Quantidade de titulares envolvidos no incidente.',
                '2.7': 'Evidência no incidente ter ocorrido.',
                '2.8': 'Existe um RIPD da atividade mapeada.',
                '2.9': 'Tipo de dados envolvidos no incidente.',
                '2.10': 'Categoria dos dados envolvidos no incidente.',
                '2.11': 'Dados sensíveis envolvidos no incidente.',
                '2.12': 'Descrição dos motivos do incidente ocorrer e descoberta.',
                '2.13': 'Selecione as categoria de incidentes mais específica.',
                '3.1': 'Indique o impacto para os titulares.',
                '3.2': 'Indique o impacto para os negócios da organização/empresa.',
                '3.3': 'Indique o impacto para os tarceiros.',
                '3.4': 'Indique as medidas de contenção.',
                '3.5': 'Indique as medidas de erradicação.',
                '3.6': 'Indique as medidas de recuperação.',
                '3.7': 'Indique as novas medidas adotadas.',
                '4.1': 'Os titulares já foram comunicados, quando e de que forma.',
                '4.2': 'Quem deseja comunicar'
            }
        }
    },
    methods: {
        async save(opcao) {
            if(opcao === 'Concluído') {
                this.form.finalizado = true;
                this.form.status = 'Concluído';
            }
            if(!this.form.desativado && !this.form.ativo) this.form.ativo = true;

            this.form.qtdTipotitular = this.form.qtdTipotitular.map(t => ({ tipo: t.tipo._id, qtd: t.qtd }));

            const method = this.form._id ? 'put' : 'post';
            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async saveTextAta(){
            await this.$http.put(`/v1/${this.route}`, this.form);
            window.location.href = `${this.url_api}/v1/incidentes/ataReuniao/${this.$route.params.id}?token=${this.$store.state.token}`;
        },
        next() {
            if(this.etapa < 4) return this.etapa++; 
        },
        async enviarATA() {
            const req = await this.$http.post(`/v1/${this.route}/enviarATA`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Ata enviada para participantes com sucesso!");
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async comunicarIncidente(){
            const req = await this.$http.post(`/v1/${this.route}/comunicar`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Comunicados com sucesso!");
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        addLink (){
            if(!this.link) return this.$vToastify.error("Digite uma URL");
            this.form.evidencias.push({ link: this.link });
            this.link = "";
        },
        customLabel ({ nome, sensivel, critico })  {
            return `${nome}${sensivel ? ' - DADO SENSÍVEL': ''}${critico ? ' - DADO CRÍTICO': ''}`
        },
        changeComunicacao(){
            if(this.form.tipoComunicao === "Completa") {
                this.form.avaliacaoRisco = "O incidente de segurança pode acarretar risco ou dano relevante aos titulares";
            }
            if(this.form.tipoComunicao === "Preliminar") {
                this.form.avaliacaoRisco = "Risco do incidente aos titulares ainda está sendo apurado";
            }
            if(this.form.tipoComunicao === "Complementar") {
                this.form.avaliacaoRisco = "O incidente não acarretou risco ou dano relevante aos titulares";
            }
        },
        changeBusca(item){
            if(item.check) {
                this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
            }else {
                const index = this.selecionadosBusca.map(el => el.id).indexOf(item._id);
                if(index !== -1){
                    this.selecionadosBusca.splice(index, 1);
                }
            }
        },
        addParticipante(){
            if(!this.outroParticipante.nome || !this.outroParticipante.email) return this.$vToastify.error("Informe nome e email");
            this.form.outrosParticipantes.push({ nome: this.outroParticipante.nome, email: this.outroParticipante.email });
            this.outroParticipante = { nome: "", email: "" };
        },
        removerParticipantes(){
            for(let i = 0; i < this.selecionadosParticipantes.length;i++){
                const participante = this.selecionadosParticipantes[i];
                const idx = this.form.outrosParticipantes.map(el => el.email).indexOf(participante.email);
                this.form.outrosParticipantes.splice(idx, 1);
            }
            this.selecionadosParticipantes = [];
        },
        async addConvidado(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/comunicar/addConvidado`, { _id: id, form: this.outro });
            if (req.data.success) {
                this.$vToastify.success("Adicionado com sucesso");
                this.selecionadosBusca = [];
                this.convidados = req.data.convidados;
                this.totalConvidados = req.data.total;
                this.outro = { nome: "", email: "" };
                this.startBusca();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async addConvidados(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/comunicar/addConvidados`, { _id: id, selecionados: this.selecionadosBusca });
            if (req.data.success) {
                this.$vToastify.success(`${req.data.addCount} adicionados com com sucesso`);
                this.selecionadosBusca = [];
                this.convidados = req.data.convidados;
                this.totalConvidados = req.data.total;
                this.startBusca();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        selecionarTodos() {
            const acao = this.selecionadosBusca.length > 0 ? 'removerTudo' : 'adicionarTudo';

            if (acao === 'removerTudo') this.selecionadosBusca = [];

            if (acao === 'adicionarTudo') {
                for(let i = 0; i < this.busca.length; i++){
                    const item = this.busca[i];
                    item.check = true;
                    this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
                }
            }
        },
        selecionadosControlParticipante(item, e){
            if (this.selecionadosParticipantes.map(el => el.email).indexOf(item.email) > -1 && !e.target.checked) {
                this.selecionadosParticipantes.splice(this.selecionadosParticipantes.map(el => el.email).indexOf(item.email), 1);
            } else {
                this.selecionadosParticipantes.push(item);
            }
        },
        selecionadosControl(item, e){
            if (this.selecionadosConvidados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
                this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el._id).indexOf(item._id), 1);
            } else {
                this.selecionadosConvidados.push(item);
            }
        },
        selecionarTodosConvidados(){
            for(let i = 0; i < this.convidados.length; i++){
                const item = this.convidados[i];
                if(this.selecionadosConvidados.map(el => el._id).indexOf(item._id) ==! -1) this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el._id).indexOf(item._id), 1);
                else this.selecionadosConvidados.push(item);
            }
        },
        selecionarTodosParticipantes(){
            for(let i = 0; i < this.form.outrosParticipantes.length; i++){
                const item = this.form.outrosParticipantes[i];
                if(this.selecionadosParticipantes.map(el => el.email).indexOf(item.email) ==! -1) this.selecionadosParticipantes.splice(this.selecionadosParticipantes.map(el => el.email).indexOf(item.email), 1);
                else this.selecionadosParticipantes.push(item);
            }
        },
        async removerConvidados(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/comunicar/removerConvidados`, { _id: id, selecionados: this.selecionadosConvidados });
            if (req.data.success) {
                this.$vToastify.success("Removidos com com sucesso!");
                this.selecionadosConvidados = [];
                this.convidados = req.data.convidados;
                this.totalConvidados = req.data.total;
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async startBusca(){
            if(this.selecionadosBusca.length > 0) {
                return this.$vToastify.error("Adicione os selecionados aos contatos, antes de realizar uma nova busca!");
            }
            if(this.buscaParms.tipo === 'Titular') {
                const reqbusca = await this.$http.post(`/v1/titulares/list`, { all: true, tipoTitular: this.buscaParms.tipoTitular });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            if(this.buscaParms.tipo === 'Fornecedor') {
                const reqbusca = await this.$http.post(`/v1/fornecedores/list`, { all: true, agente: this.buscaParms.agente });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
        },
        onSelect () {
            setTimeout(function() {
                const tags = document.getElementsByClassName('multiselect__tag');
                for(let i = 0; i < tags.length; i++){
                    const tag = tags[i];
                    if(tag.children[0].innerHTML.match('CRÍTICO')){
                        tag.style.backgroundColor = "#f0ad4e";
                    }
                    if(tag.children[0].innerHTML.match('SENSÍVEL')){
                        tag.style.backgroundColor = "#d9534f";
                    }
                }
            }, 500);

            if(this.form.dados && this.form.dados.length){
                 for(let i = 0; i < this.form.dados.length; i++){
                    const item = this.form.dados[i];
                    if(!item || !item._id) continue;
                    
                    if(item.sensivel && !this.form.dadossensiveis.map(el => el._id).includes(item._id.toString())){
                        const dadosensivel = this.dadossensiveis.find(el => el._id.toString() === item._id.toString());
                        if(dadosensivel) this.form.dadossensiveis.push(dadosensivel);
                    }

                    if(item.categoria && item.categoria._id && !this.form.categoriadados.map(el => el._id).includes(item.categoria._id.toString())){
                        const categoria = this.categorias.find(el => el._id.toString() === item.categoria._id.toString());
                        if(categoria) this.form.categoriadados.push(categoria);
                    }
                }
            }
        },
        async onSelectAtividade (atividade) {

            if(!atividade) return;
         
            if(atividade.titularmenor) this.form.titularmenor = atividade.titularmenor;

            if(atividade.tipotitular && atividade.tipotitular.length) {
                for(let i = 0; i < atividade.tipotitular.length; i++){
                    const item = atividade.tipotitular[i] && atividade.tipotitular[i]._id ? atividade.tipotitular[i]._id : atividade.tipotitular[i];
                    if(!this.form.tipotitular.map(el => el._id).includes(item)){
                        const atividade = this.tipotitulares.find(el => el._id.toString() === item.toString());
                        if(atividade) this.form.tipotitular.push(atividade);
                    }
                }
            }

            if(atividade.dados && atividade.dados.length) {
                for(let i = 0; i < atividade.dados.length; i++){
                    const item = atividade.dados[i] && atividade.dados[i]._id ? atividade.dados[i]._id : atividade.dados[i];
                    if(!this.form.dados.map(el => el._id).includes(item)){
                        const atividade = this.dados.find(el => el._id.toString() === item.toString());
                        if(atividade) this.form.dados.push(atividade);
                    }
                }
                this.onSelect();
            }

            const risco = await this.$http.get(`/v1/riscos/atividade/${atividade._id}`);
            if(risco.data){
                this.form.possuiRIPD = true;
                if(risco.data.impacto_titular && risco.data.impacto_titular.length) {
                    for(let i = 0; i < risco.data.impacto_titular.length; i++){
                        const item = risco.data.impacto_titular[i];
                        if(!item || !item._id) continue;

                        if(!this.form.impacto_titular.map(el => el._id).includes(item._id)){
                            const impacto = this.impactos_titular.find(el => el._id.toString() === item._id.toString());
                            if(impacto) this.form.impacto_titular.push(impacto);
                        }
                    }
                }
                if(risco.data.impacto_negocio && risco.data.impacto_negocio.length) {
                    for(let i = 0; i < risco.data.impacto_negocio.length; i++){
                        const item = risco.data.impacto_negocio[i];
                        if(!item || !item._id) continue;

                        if(!this.form.impacto_negocio.map(el => el._id).includes(item._id)){
                            const impacto = this.impactos_negocio.find(el => el._id.toString() === item._id.toString());
                            if(impacto) this.form.impacto_negocio.push(impacto);
                        }
                    }
                }
            }

        },
        async onSelectTipotitular(tipoTitular){
            for(let i = 0; i < tipoTitular.length; i++){
                const tipo = tipoTitular[i];
                const idx = this.form.qtdTipotitular.map(el => el.tipo._id.toString()).indexOf(tipo._id.toString());
                if(idx === -1) this.form.qtdTipotitular.push({ tipo, qtd: 0 });
            }

            for(let i = 0; i < this.form.qtdTipotitular.length; i++){
                const qtdTipotitular = this.form.qtdTipotitular[i];
                const idx = tipoTitular.map(el => el._id.toString()).indexOf(qtdTipotitular.tipo._id.toString());
                if(idx === -1) this.form.qtdTipotitular.splice(idx);
            }
        },
        send(file, xhr, formData) {
            formData.append('incidente', this.$route.params.id);
        },
        updateEvidencias(file, response) {
            this.form.evidencias.push(response.file);
            this.$refs.evidencias.removeAllFiles();
        },
        updateAtaReuniaoDocumento(file, response) {
            this.form.ataReuniaoDocumento = response.file;
            this.$refs.ataReuniaoDocumento.removeAllFiles();
        },
        updateAnexos(file, response) {
            this.form.anexos.push(response.file);
            this.$refs.anexos.removeAllFiles();
        },
        async updateDestinatario() {
            this.$refs.destinatario.removeAllFiles();
            const id = this.$route.params.id;
            const listConvidados = await this.$http.post(`/v1/${this.route}/comunicar/list`, { all: true, incidente: id });
            this.convidados = listConvidados.data.data;
            this.totalConvidados = listConvidados.data.total;
            this.$modal.hide('importarModal');
        },
        back() {
            this.etapa === 1 ? this.$router.back() : this.etapa--;
        },
        async excluirDefinitivamente(){
            this.$confirm({
                title: 'Excluir definitivamente',
                message: `Deseja excluir o incidente selecionado dos registros?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(!confirm) return;
                    const id = this.$route.params.id;
                    await this.$http.delete(`/v1/${this.route}/excluir/${id}`);
                    this.$vToastify.success("Removido com sucesso!");
                    this.$router.back();
                }
            })
        }
    },
    async beforeMount() {
        let loader = this.$loading.show({
            container: null,
            canCancel: true
        });

        const id = this.$route.params.id;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
        this.fornecedores = listFornecedores.data.data; 

        const listTitulares = await this.$http.post(`/v1/titulares/list`, { all: true});
        this.titulares = listTitulares.data.data; 

        const listAtividades = await this.$http.post(`/v1/atividades/list`, { all: true });
        this.atividades = listAtividades.data.data; 

        const listCategorias = await this.$http.post('/v1/categoriadados/list', { all: true });
        this.categorias = listCategorias.data.data;

        const listDados = await this.$http.post(`/v1/dados/list`, { all: true});
        this.dados = listDados.data.data;

        const listDadosSensiveis = await this.$http.post(`/v1/dados/list`, { all: true, sensivel: true});
        this.dadossensiveis = listDadosSensiveis.data.data;
        
        const listTipoTitulares = await this.$http.post(`/v1/tipotitulares/list`, { all: true});
        this.tipotitulares = listTipoTitulares.data.data;
        
        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentestratamento = listAgentesTratamento.data.data;

        const listimpactosTitular = await this.$http.post(`/v1/impactos/list`, { all: true, impactado: "TITULARES DE DADOS"});
        this.impactos_titular = listimpactosTitular.data.data;

        const listimpactosNegocios = await this.$http.post(`/v1/impactos/list`, { all: true, impactado: "NEGOCIOS DA EMPRESA"});
        this.impactos_negocio = listimpactosNegocios.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;


        this.startBusca();
        
        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            this.onSelect();
            if(req.data.quandoOcorreu) this.form.quandoOcorreu = moment(req.data.quandoOcorreu).format("YYYY-MM-DD HH:mm");
            if(req.data.quandoDescobrimos) this.form.quandoDescobrimos = moment(req.data.quandoDescobrimos).format("YYYY-MM-DD HH:mm");
            if(req.data.quandoRegistramos) this.form.quandoRegistramos = moment(req.data.quandoRegistramos).format("YYYY-MM-DD HH:mm");
            if(req.data.dataReuniao) this.form.dataReuniao = moment(req.data.dataReuniao).format("YYYY-MM-DD HH:mm");
            if(req.data.quandoFoiComunicado) this.form.quandoFoiComunicado = moment(req.data.quandoFoiComunicado).format("YYYY-MM-DD HH:mm");
            if(req.data.quandoFoiComunicadoANPD) this.form.quandoFoiComunicadoANPD = moment(req.data.quandoFoiComunicadoANPD).format("YYYY-MM-DD HH:mm");
            if(!req.data.dadosNotificante) this.form.dadosNotificante = { nome: "", cpfCnpj: "", telefone: "", email: "" };
            const listConvidados = await this.$http.post(`/v1/${this.route}/comunicar/list`, { all: true, incidente: id });
            this.convidados = listConvidados.data.data;
            this.totalConvidados = listConvidados.data.total;
        }

        loader.hide();
    },
}
</script>